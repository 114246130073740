//
// Card
//

.card-translucent {
  background-color: rgba(18, 91, 152, 0.08);
}

.card {
  overflow: hidden;
}
