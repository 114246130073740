//
// Badge
//

// General styles

.badge {
  text-transform: $badge-text-transfom;

  a {
    color: $white;
  }
}

// Size variations

.badge-md {
  padding: 0.65em 1em;
}

.badge-lg {
  padding: 0.85em 1.375em;
}

// Multiple inline badges

.badge-inline {
  margin-right: 0.625rem;

  + span {
    top: 2px;
    position: relative;

    > a {
      text-decoration: underline;
    }
  }
}

// Badge spacing inside a btn with some text

.btn {
  .badge {
    &:not(:first-child) {
      margin-left: 0.5rem;
    }
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}

.ribbon {
  margin: 0;
  padding: 0;
  background: $primary;
  color: white;
  padding: 0.5em 2px;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(30%) translateY(0%) rotate(45deg);
  transform-origin: top left;
}
.ribbon:before,
.ribbon:after {
  content: "";
  position: absolute;
  top: 0;
  margin: 0 -1px;
  padding: 0 25px; /* tweak */
  width: 100%;
  height: 100%;
  background: $primary;
}
.ribbon:before {
  right: 100%;
}

.ribbon:after {
  left: 100%;
}

.ribbon-warning {
  margin: 0;
  padding: 0;
  background: $yellow;
  color: white;
  padding: 0.5em 2px;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(30%) translateY(0%) rotate(45deg);
  transform-origin: top left;
}
.ribbon-warning:before,
.ribbon-warning:after {
  content: "";
  position: absolute;
  top: 0;
  margin: 0 -1px;
  padding: 0 25px; /* tweak */
  width: 100%;
  height: 100%;
  background: $yellow;
}
.ribbon-warning:before {
  right: 100%;
}

.ribbon-warning:after {
  left: 100%;
}

.coming-soon {
  background-color: $gray-100;
}
